import { Button, InputRHF, RadioGroupRHF, useLoading } from '@aemiko/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Text } from '../../Base';
import { useCreateEmployeeMutation, UserRole } from '../../graphQL';

const required = 'required';

type EmployeeForm = {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
};

const roleToCopy: Record<UserRole, string> = {
  [UserRole.Admin]: 'Manager',
  [UserRole.Limited]: 'Employee',
  [UserRole.Owner]: 'Owner',
  [UserRole.Supreme]: 'Supreme',
  [UserRole.User]: 'Customer',
};

export const CreateEmployee = () => {
  const form = useForm<EmployeeForm>();
  const [createEmployee] = useCreateEmployeeMutation();
  const { setLoading } = useLoading();
  const history = useHistory();

  const submit = form.handleSubmit(async createUserInput => {
    try {
      setLoading(true);
      await createEmployee({ variables: { createUserInput } });
      history.push('/store/team');
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className="pa5 mw6 center">
      <Text.h2 className="mb4">Create Employee</Text.h2>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <div className="flex flex-column gap-3 mb3">
            <InputRHF
              name="firstName"
              controlProps={{ label: 'First Name' }}
              rules={{ required }}
            />
            <InputRHF name="lastName" controlProps={{ label: 'Last Name' }} rules={{ required }} />
            <InputRHF name="email" controlProps={{ label: 'Email' }} rules={{ required }} />
            <RadioGroupRHF
              name="role"
              options={[UserRole.Admin, UserRole.Limited].map(r => ({
                id: r,
                label: roleToCopy[r],
              }))}
              withLabel
              rules={{ required }}
              controlProps={{ label: 'User Role' }}
            />
          </div>
        </form>
      </FormProvider>
      <Button kind="info" onClick={submit}>
        Submit
      </Button>
    </div>
  );
};
