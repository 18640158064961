import React from 'react';
import { useCurrentUser } from '../Components/Context/AuthContext';
import { PaddedLayout } from '../Components/Layout/PaddedLayout';

export const Home = () => {
  const { user } = useCurrentUser();
  return (
    <PaddedLayout>
      Welcome, {user.firstName} {user.lastName}
    </PaddedLayout>
  );
};
