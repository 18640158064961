import { MoreVerticalOutline } from '@aemiko/icons';
import { Button, Grid, Search, useTheme, When } from '@aemiko/ui';
import { ITableProps, kaReducer, Table } from 'ka-table';
import { hideDetailsRow, search, showDetailsRow } from 'ka-table/actionCreators';
import { DataType, EditingMode, PagingPosition, SortDirection, SortingMode } from 'ka-table/enums';
import { Column } from 'ka-table/models';
import { IDataRowProps } from 'ka-table/props';
import { DispatchFunc } from 'ka-table/types';
import { useState, PureComponent } from 'react';
import { Text } from '../../Base';
import { ModuleLayout } from '../../Components/Layout/ModuleLayout';
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  Sector,
} from 'recharts';
import '../../styles/ka-table-custom.scss';
import moment from 'moment';
import styled from 'styled-components';
import { ProductCategory, useOrdersPageQuery } from '../../graphQL';
import { LoadingScreen, LoadingWidet } from '../../Components/Layout/LoadingScreen';
import { Nullable } from '@aemiko/utils';

const columns: Column[] = Array(15)
  .fill(undefined)
  .map((_, index) => ({
    key: 'column' + index,
    colGroup: { style: { minWidth: 100 } },
    width: index === 0 ? 150 : 200,
    title: 'Column ' + index,
    type: DataType.String,
    sortDirection: index === 1 ? SortDirection.Ascend : undefined,
  }));

const dataArray = Array(30)
  .fill(undefined)
  .map((_, index) =>
    columns.reduce(
      (previousValue: any, currentValue) => {
        previousValue[currentValue.key] = `${currentValue.key} row:${index}`;
        return previousValue;
      },
      { id: index }
    )
  );

const data = [
  {
    date: '2000-01',
    count: 4000,
    ma: 2400,
  },
  {
    date: '2000-02',
    count: 3000,
    ma: 1398,
  },
  {
    date: '2000-03',
    count: 2000,
    ma: 9800,
  },
  {
    date: '2000-04',
    count: 2780,
    ma: 3908,
  },
  {
    date: '2000-05',
    count: 1890,
    ma: 4800,
  },
  {
    date: '2000-06',
    count: 2390,
    ma: 3800,
  },
  {
    date: '2000-07',
    count: 3490,
    ma: 4300,
  },
  {
    date: '2000-08',
    count: 4000,
    ma: 2400,
  },
  {
    date: '2000-09',
    count: 3000,
    ma: 1398,
  },
  {
    date: '2000-10',
    count: 2000,
    ma: 9800,
  },
  {
    date: '2000-11',
    count: 2780,
    ma: 3908,
  },
  {
    date: '2000-12',
    count: 1890,
    ma: 4800,
  },
];

const dataPie = [
  { name: ProductCategory.Flower, value: 900 },
  { name: ProductCategory.Concentrate, value: 950 },
  { name: ProductCategory.Edible, value: 550 },
  { name: ProductCategory.Other, value: 500 },
  { name: ProductCategory.PreRoll, value: 300 },
  { name: ProductCategory.Hookah, value: 50 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#000000', '#FFFFFF'];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`Volume ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const DetailsRow: React.FC<IDataRowProps> = ({ rowData }) => {
  return (
    <div>
      <Text.h3>DetailsRow #{rowData.id}</Text.h3>
      <Text.body>Column 1: {rowData.column1}</Text.body>
      <Text.body>Column 2: {rowData.column2}</Text.body>
    </div>
  );
};

const tablePropsInit: ITableProps = {
  columns,
  data: dataArray,
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 10,
    pageSizes: [5, 10, 15],
    position: PagingPosition.Bottom,
  },
  sortingMode: SortingMode.Single,
  // editingMode: EditingMode.Cell,
  rowKeyField: 'id',
  search: ({ searchText }) => true,
  // columnResizing: true,
  columnReordering: true,
};

/*

 ({ searchText, rowData, column }) => {
    if (column.key === 'passed') {
      return (
        (searchText === 'false' && !rowData.passed) || (searchText === 'true' && rowData.passed)
      );
    }
  },


  [
    { key: 'id', title: 'Id', dataType: DataType.Number, isEditable: false },
    { key: 'column1', title: 'Column 1', dataType: DataType.String },
    { key: 'column2', title: 'Column 2', dataType: DataType.String },
    { key: 'column3', title: 'Column 3', dataType: DataType.String },
    { key: 'column4', title: 'Column 4', dataType: DataType.String },
  ]

*/

export const Orders = () => {
  const { theme } = useTheme();
  const { data: q, loading } = useOrdersPageQuery();
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch: DispatchFunc = action => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };
  const [selectedGroup, setSelectedGroup] = useState<number>(0);

  const transactionData = q?.transactions ?? [];

  return (
    <>
      <Text.h1 className="mb5" kind="white">
        Orders
      </Text.h1>
      <When isTruthy={loading}>
        <LoadingWidet className="pv5" />
      </When>
      <When isTruthy={!loading}>
        <div className="flex flex-row gap-3 mb5" style={{ width: '100%', height: '16rem' }}>
          <div style={{ width: '100%', height: '100%' }}>
            <Text.label className="mb3">Volume</Text.label>
            <ResponsiveContainer className="mb4" height="100%" width="100%">
              <ComposedChart width={600} height={200} data={data}>
                <XAxis
                  dataKey="date"
                  tickFormatter={date => moment(date).format('MMM')}
                  axisLine={{ stroke: 'transparent' }}
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill={theme['secondary-400']} />
                <Line type="monotone" dataKey="ma" stroke={theme['warning-400']} />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div style={{ width: '100%', height: '100%' }}>
            <Text.label className="mb1">Categories</Text.label>
            <Text.caption kind="white">{moment().format('MMMM YYYY')}</Text.caption>
            <ResponsiveContainer className="mb4" height="100%" width="100%">
              <PieChart width={900} height={900}>
                <Pie
                  data={dataPie}
                  innerRadius={65}
                  outerRadius={85}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  nameKey="name"
                  activeIndex={selectedGroup}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, idx) => setSelectedGroup(idx)}
                >
                  <Tooltip />
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <ModuleLayout>
          <Search
            onSearch={searchQuery => dispatch(search(searchQuery))}
            className="b-radius-bold mb3 mw6 top-element"
          />
          <div className="custom-theme-demo">
            {/*

const columns: Column[] = Array(15)
  .fill(undefined)
  .map((_, index) => ({
    key: 'column' + index,
    colGroup: { style: { minWidth: 100 } },
    width: index === 0 ? 150 : 200,
    title: 'Column ' + index,
    type: DataType.String,
    sortDirection: index === 1 ? SortDirection.Ascend : undefined,
  }));

   transactions {
    createdAt
    user {
      email
      phone
    }
    items {
      product {
        name
      }
      productQuantity
      cost
      itemCount
    }
  }



  const dataArray = Array(30)
  .fill(undefined)
  .map((_, index) =>
    columns.reduce(
      (previousValue: any, currentValue) => {
        previousValue[currentValue.key] = `${currentValue.key} row:${index}`;
        return previousValue;
      },
      { id: index }
    )
  );

              */}
            <Table
              {...tableProps}
              groups={[{ columnKey: 'groupId' }]}
              columns={[
                {
                  key: 'groupId',
                  title: 'Transaction',
                  type: DataType.String,
                  width: 150 as Nullable<number>,
                },
                { key: 'productName', title: 'Product', type: DataType.String },
                { key: 'productCategory', title: 'Category', type: DataType.String, width: 150 },
                { key: 'productQuantity', title: 'Size', type: DataType.String, width: 150 },
                { key: 'itemCount', title: 'Quantity', type: DataType.Number, width: 150 },
                { key: 'amount', title: 'Amount', type: DataType.String },
                { key: 'user', title: 'User', type: DataType.String },
                { key: 'createdAt', title: 'Timestamp', type: DataType.String },
              ].map(c => ({
                ...c,
                colGroup: { style: { minWidth: 100 } },
                width: c.width ?? 200,
              }))}
              data={[...transactionData.reverse()].flatMap((t, id) => {
                return t.items.map(i => ({
                  ...i,
                  id,
                  productCategory: i.product.category,
                  groupId: t.uuid,
                  productName: i.product.name,
                  amount: `$${i.cost}`,
                  user: (t.user?.phone ? t.user.phone : t.user?.email) ?? ' - ',
                  createdAt: moment(t.createdAt).format('MM-DD-YYYY hh:mm:ss'),
                }));
              })}
              dispatch={dispatch}
              childComponents={{
                headCellContent: {
                  content: ({ column }) => {
                    if (column.key === 'expand') {
                      return null as any;
                    }
                    return (
                      <div className="inline-flex flex-row items-center gap-2 nl2">
                        <MoreVerticalOutline fill="#FFFFFF" width={18} height={18} />
                        <Text.bodyBold kind="white">{column.title}</Text.bodyBold>
                      </div>
                    );
                  },
                },
                headCell: {
                  elementAttributes: props => {
                    if (props.column.key === 'expand') {
                      return {
                        style: {
                          ...props.column.style,
                          position: 'sticky',
                          left: 0,
                          zIndex: 10,
                          boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 24px',
                        },
                      };
                    }
                  },
                },
                cell: {
                  elementAttributes: props => {
                    if (props.column.key === 'expand') {
                      return {
                        style: {
                          ...props.column.style,
                          position: 'sticky',
                          left: 0,
                          backgroundColor: '#1e1f23',
                          color: 'black',
                          boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 24px',
                        },
                      };
                    }
                  },
                },
                cellText: {
                  content: ({ column, value, isDetailsRowShown, rowKeyField, rowKeyValue }) => {
                    return (
                      <div className="flex flex-row gap-2">
                        {column.key === 'expand' ? (
                          <Button
                            kind="black"
                            size="s"
                            onClick={() =>
                              dispatch(
                                isDetailsRowShown
                                  ? hideDetailsRow(rowKeyValue)
                                  : showDetailsRow(rowKeyValue)
                              )
                            }
                          >
                            Expand
                          </Button>
                        ) : (
                          <Text.body
                            className="pa2"
                            kind={column.key === 'expand' ? 'black' : 'white'}
                          >
                            {value}
                          </Text.body>
                        )}
                      </div>
                    );
                  },
                },
                detailsRow: {
                  elementAttributes: () => ({
                    style: {
                      backgroundColor: '#eee',
                      color: 'black',
                    },
                  }),
                  content: DetailsRow,
                },
              }}
            />
          </div>
        </ModuleLayout>
      </When>
    </>
  );
};

const IdkContainer = styled.div`
  padding: 1rem;
  background: #161a1e;
  border-radius: 0.25rem;
`;
