import clsx from 'clsx';
import keyboardKey from 'keyboard-key';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Icon, ShadedBackdrop } from '../Common';
import { PhantomButton } from '../PhantomButton';
import { DomPortal } from '../Portal';
import { Text } from '../Text';
import { AemikoSize } from '../Theme';
import { addEvent, disableBodyScroll, enableBodyScroll, removeEvent } from '../utils/domUtils';

const sizeMap: Record<AemikoSize, FlattenSimpleInterpolation> = {
  xs: css`
    width: 100%;
    max-width: 20rem;
    padding: 2rem;
  `,
  s: css`
    width: 100%;
    max-width: 20rem;
    padding: 2rem;
  `,
  m: css`
    width: 100%;
    max-width: 28rem;
    padding: 2rem;
  `,
  l: css`
    width: 100%;
    max-width: 35rem;
    padding: 2.25rem 2.25rem;
  `,
  xl: css`
    width: 100%;
    max-width: 45rem;
    padding: 2rem 3rem;
  `,
};

const titleMap: Record<
  AemikoSize,
  (p: PropsWithChildren<{ className?: string }>) => React.ReactElement
> = {
  xs: Text.h4,
  s: Text.h3,
  m: Text.h2,
  l: Text.h1,
  xl: Text.h1,
};

type ModalProps = Pick<
  React.HTMLProps<HTMLDivElement>,
  'id' | 'style' | 'className' | 'aria-label'
> & {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: AemikoSize;
  title?: string;
};

export const Modal = ({
  isOpen,
  onClose,
  children,
  size = 'm',
  title,
  className,
  ...divProps
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const T = titleMap[size];

  const onBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!modalRef.current || !modalRef.current.contains(e.target as any)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && modalRef.current) {
      disableBodyScroll(modalRef.current);
    } else if (modalRef.current) {
      enableBodyScroll(modalRef.current);
    }
  }, [isOpen, modalRef]);

  useEffect(() => {
    const closeOnEsc = (e: React.KeyboardEvent) => {
      const code = keyboardKey.getCode(e);
      if (!code) return;
      if (code === keyboardKey.Escape) {
        e.preventDefault();
        onClose();
      }
    };
    addEvent(document.body, 'keydown', closeOnEsc);
    return () => removeEvent(document.body, 'keydown', closeOnEsc);
  }, [onClose]);

  return (
    <DomPortal wrapperId={`modal-portal-${size}`}>
      <ModalBackdrop className={clsx({ ['backdrop-open']: isOpen })} onClick={onBackdropClick}>
        <ModalContainer
          {...divProps}
          className={clsx(`modal-size-${size}`, { ['modal-open']: isOpen }, className)}
          ref={modalRef}
        >
          <PhantomButton
            type="button"
            onClick={() => onClose()}
            style={{ top: 14, right: 17 }}
            className="absolute"
          >
            <Icon.close size="1.5rem" />
          </PhantomButton>
          {/* careful, assuming tacheyons is installed on whoever is using this, fix later */}
          {title && <T className="mb3 mt0">{title}</T>}
          {children}
        </ModalContainer>
      </ModalBackdrop>
    </DomPortal>
  );
};

const ModalContainer = styled.div`
  position: relative;
  background: white;
  margin: auto;
  display: none;
  &.modal-open {
    display: block;
  }
  ${Object.entries(sizeMap).map(
    ([key, value]) => css`
      &.modal-size-${key} {
        ${value}
      }
    `
  )};
`;

const ModalBackdrop = styled(ShadedBackdrop)`
  display: flex;
`;
