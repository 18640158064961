/*
~~~~ Used to use Cera Pro for Headers ~~~~
const FONT_FAMILY_CERA_PRO = {
  regular: 'CeraPro-Regular',
  italic: 'CeraPro-Regular-Italic',
  bold: 'CeraPro-Bold',
} as const;

*/

const FONT_FAMILY_LAB_GROTESQUE = {
  light: 'LabGrotesque-Light',
  regular: 'LabGrotesque-Regular',
  italic: 'LabGrotesque-Italic',
  bold: 'LabGrotesque-Bold',
};

export const BODY_FONT_FAMILY = FONT_FAMILY_LAB_GROTESQUE;
export const HEADER_FONT_FAMILY = FONT_FAMILY_LAB_GROTESQUE;
