import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';

const SvgLogo = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="#8ED69B"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path d="M15.529 28.003h12.463l-3.895-4.2-8.568 4.2ZM15.157 27.303l10.506-5.676L28 10.63l-7.79 4.777-5.053 11.896ZM13.973 26.709 18.647 15.4 13.973 0 9.299 15.4l4.674 11.309ZM12.463 28.003H0l3.895-4.2 8.568 4.2ZM12.846 27.303 2.34 21.627.003 10.63l7.79 4.777 5.053 11.896Z" />
  </svg>
);

const ForwardRef = forwardRef(SvgLogo);
export default ForwardRef;
