import { CloseOutline, Logo, LogoutOutline, MenuOutline } from '@aemiko/icons';
import { BODY_FONT_FAMILY, ThemedElement, useTheme } from '@aemiko/ui';
import clsx from 'clsx';
import { useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { Text } from '../../Base';
import { useCurrentUser } from '../Context/AuthContext';
import { useNav } from './useNav';

export const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { theme } = useTheme();
  const { navItems } = useNav();
  const { logOut } = useCurrentUser();
  const history = useHistory();
  const toggleMenuOpen = () => setMenuOpen(o => !o);

  return (
    <Wrapper aemikoTheme={theme}>
      <MenuSmall className="pa3">
        <MenuOutline width={27} height={27} fill="#cccccc" onClick={toggleMenuOpen} />
      </MenuSmall>
      <SideNavContainer aemikoTheme={theme} className={clsx({ menuOpen })}>
        <MenuSmall>
          <CloseOutline
            className="nl1"
            fill="#FFFFFF"
            width={27}
            height={27}
            onClick={toggleMenuOpen}
          />
        </MenuSmall>
        <BrandContainer className="flex flex-row items-center gap-3 mb4 pb1">
          <BrandText>aemiko</BrandText>
          <Logo fill={theme['primary-600']} width={25} height={25} className="nt1" />
        </BrandContainer>
        <div className="flex flex-column gap-3">
          {navItems.map(({ iconComponent: C, isActive, ...item }) => (
            <div>
              {item.isSection && <Hr />}
              <ItemContainer
                className={clsx('flex flex-row gap-2 items-center', isActive && 'is-active')}
              >
                <C width={20} height={20} fill="#FFFFFF" />
                <Text.link to={item.goTo}>
                  <ItemText>{item.title}</ItemText>
                </Text.link>
              </ItemContainer>
            </div>
          ))}
          <ItemContainer className="flex flex-row gap-2 items-center">
            <LogoutOutline width={20} height={20} fill="#FFFFFF" />
            <Text.linkButton className="no-underline" onClick={logOut}>
              <ItemText>Log Out</ItemText>
            </Text.linkButton>
          </ItemContainer>
        </div>
      </SideNavContainer>
    </Wrapper>
  );
};

const paddedH = css`
  padding-left: 1.95em;
  padding-right: 1.95em;
`;

const BrandContainer = styled.div`
  ${paddedH};
  padding-top: 4em;
`;

const ItemContainer = styled.div`
  ${paddedH};
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  &.is-active {
    background: #436695;
  }
  // border-radius: 50vh;
  margin: 0.25rem 0;
`;

const ItemText = styled(Text.bodySmall)`
  color: #ffffff;
`;

const Wrapper = styled.div<ThemedElement>`
  background-color: #0b111f; // #161a1e; // ${({ aemikoTheme }) => aemikoTheme['secondary-500']};
`;

const MenuSmall = styled.div`
  @media only screen and (min-width: 50em) {
    display: none;
  }
`;

const BrandText = styled(Text.h1)`
  color: white;
`;

const SideNavContainer = styled.div<ThemedElement>`
  // padding: 2.75em 1.95em;
  padding: 0 0.3rem;
  background-color: #0b111f;
  @media only screen and (max-width: 50em) {
    padding-top: 1em;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 9999;
    overflow: auto;
    transition: transform 0.5s;
    will-change: transform;
    display: flex;
    flex-direction: column;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    /* enables momentum scrolling in iOS overflow elements */
    -webkit-overflow-scrolling: touch;
    &.menuOpen {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }
`;

export const Hr = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #cccccc;
  margin-bottom: 2em;
`;
