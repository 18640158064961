import { Alert, Button, InputRHF, useLoading } from '@aemiko/ui';
import { wait } from '@aemiko/utils';
import * as qs from 'query-string';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../../Base';
import { PaddedLayout } from '../../Components/Layout/PaddedLayout';
import { useFinishPasswordResetMutation } from '../../graphQL';
import { setAuthToken } from '../../token';

const ONE_SECOND = 1000;

type ChangePassForm = {
  newPassword: string;
  newPasswordConfirm: string;
};

const required = 'required';

export const ChangePassword = () => {
  const { setLoading } = useLoading();
  const [error, setError] = useState('');

  const history = useHistory();
  const form = useForm<ChangePassForm>();

  const [finishPasswordReset] = useFinishPasswordResetMutation();

  const location = useLocation();
  const queryString = qs.parse(location.search.replace(/^\?/, ''));
  const { email, phone, token } = queryString as { email?: string; phone?: string; token: string };

  const submit = form.handleSubmit(async ({ newPassword, newPasswordConfirm }) => {
    try {
      // shouldn't happen since the validation is in place
      if (newPassword !== newPasswordConfirm) {
        return setError("Passwords don't match");
      }
      setLoading(true);
      // gives a good effect
      await wait(1.5 * ONE_SECOND);
      const { data } = await finishPasswordReset({
        variables: { phone, email, token, newPassword },
      });
      const jwt = data?.finishPasswordReset;
      if (jwt) {
        setAuthToken(jwt.accessToken);
        history.push('/home');
      }
    } catch {
      setError('The email or password you entered is incorrect');
    } finally {
      setLoading(false);
    }
  });

  return (
    <PaddedLayout>
      <LoginContainer>
        <Text.h1 className="mb4">Change Password</Text.h1>
        {error && (
          <Alert className="mb4 dib">
            <Text.bodySmall kind="danger" className="di">
              {error}. Please try again or{' '}
            </Text.bodySmall>
            <Text.linkSmall to="/reset-password">reset your password</Text.linkSmall>.
          </Alert>
        )}
        <FormProvider {...form}>
          <form onSubmit={submit}>
            <InputRHF
              name="newPassword"
              rules={{ required }}
              type="password"
              controlProps={{ label: 'Password', className: 'mb4' }}
            />
            <InputRHF
              name="newPasswordConfirm"
              rules={{
                validate: v => form.getValues('newPassword') === v || "Passwords don't match",
              }}
              type="password"
              controlProps={{ label: 'Confirm Password', className: 'mb4', required: true }}
            />
          </form>
        </FormProvider>
        <div className="mv2">
          <Button onClick={submit} type="submit">
            <Text.bodyBig>Submit</Text.bodyBig>
          </Button>
        </div>
      </LoginContainer>
    </PaddedLayout>
  );
};

const LoginContainer = styled.div`
  width: 100%;
  max-width: 24em;
  margin: 3em auto 0 auto;
`;
