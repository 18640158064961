import jwtDecode, { JwtPayload } from 'jwt-decode';
import { clearAuthToken, getAuthToken } from './token';

export const isTokenExpired = (token: string | null) => {
  if (!token) return true;
  return doesTokenExpireWithin(token, 0);
};

export const doesTokenExpireWithin = (token: string, milliseconds: number) => {
  try {
    const decoded = jwtDecode<JwtPayload>(token);
    if (!decoded?.exp) return false;
    return decoded.exp < (Date.now() + milliseconds) / 1000;
  } catch {
    return false;
  }
};

export const logout = () => clearAuthToken();

export const isLoggedIn = () => !isTokenExpired(getAuthToken());

export const getSimplePathname = (pathname: string) =>
  pathname
    .replace(/[^a-z/]/gi, '')
    .split('/')
    .map((v, i) => (i === 0 ? '' : v || '#'))
    .join('/');
