import React from 'react';
import useScrollSpy from './useScrollSpy';

export function ScrollSpy<El extends HTMLElement>({
  children,
  sections,
}: {
  children: ({
    elementsStatusInViewport,
    currentElementIndexInViewport,
  }: {
    elementsStatusInViewport: boolean[];
    currentElementIndexInViewport: number;
  }) => JSX.Element;
  sections: React.MutableRefObject<El[]>;
}) {
  const { elementsStatusInViewport, currentElementIndexInViewport } = useScrollSpy({
    sections,
  });

  return children({
    elementsStatusInViewport,
    currentElementIndexInViewport,
  });
}
