import styled from 'styled-components';
import { Navigation } from './Navigation';
import { PaddedLayout } from './PaddedLayout';

export const NavLayout: React.FC = ({ children }) => {
  return (
    <Wrapper className="flex flex-row">
      <Navigation />
      <BodyWrapper>{children}</BodyWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const BodyWrapper = styled(PaddedLayout)`
  flex: 1;
  overflow-y: scroll;
  position: relative;
  box-sizing: border-box;
  background-color: #151619;
  // #1e1e1e
  // rgb(243, 247, 250); // off white
`;
