import { useEffect } from 'react';
import { useKeepSessionAliveMutation } from '../graphQL';
import { getAuthToken, setAuthToken } from '../token';
import { doesTokenExpireWithin, isLoggedIn } from '../utils';

const useKeepSessionAlive = (milliseconds: number) => {
  const [refresh, { loading }] = useKeepSessionAliveMutation();
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const token = getAuthToken();
      if (!isLoggedIn()) return;
      if (token && doesTokenExpireWithin(token, milliseconds) && !loading) {
        try {
          const { data } = await refresh();
          setAuthToken(data?.keepSessionAlive!);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Token refresh failure', err);
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  });
};

export default useKeepSessionAlive;
