import { NetworkStatus } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureFlag, RootUserFragment, useCurrentUserQuery } from '../../graphQL';
import { clearAuthToken } from '../../token';

export const AuthContextProvider: React.FC = ({ children }) => {
  const { data, refetch, loading, networkStatus } = useCurrentUserQuery({
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();

  const logOut = () => {
    clearAuthToken();
    history.push('/login');
  };

  if (!loading && !data) {
    throw new Error();
  }

  if (!data?.currentUser) {
    return null;
  }

  const user = data.currentUser;
  const featureFlags = data.config.featureFlags;

  const value: AuthContext = {
    logOut,
    user,
    featureFlags,
    refetching: networkStatus === NetworkStatus.refetch,
    refetch,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCurrentUser = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Outside of auth context. 😭');
  }
  return context;
};

type AuthContext = {
  logOut: () => void;
  user: RootUserFragment;
  featureFlags: FeatureFlag[];
  refetching: boolean;
  refetch: () => Promise<any>;
};

const Context = createContext<AuthContext | null>(null);
