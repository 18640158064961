import config from './config';

export const getAuthToken = () => {
  return localStorage.getItem(config.token);
};

export const setAuthToken = (token: string) => {
  return localStorage.setItem(config.token, token);
};

export const clearAuthToken = () => {
  return localStorage.removeItem(config.token);
};

export const setVerdeAuth = (token: string) => {
  return localStorage.setItem(config.verdeToken, token);
};
