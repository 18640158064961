import clsx from 'clsx';
import upperCase from 'lodash/upperCase';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CanColor, useKindTheme } from '../Theme';
import { AemikoKind } from '../Theme/types';
import { EmSize, PercentSize, RemSize, ViewportSize } from '../types';
import { FallbackText } from './Common';

export type CardSize = EmSize | RemSize | PercentSize | ViewportSize;

type ImageCardProps = {
  name: string;
  imageUuid: string;
  size?: CardSize;
  kind?: AemikoKind;
  url?: string | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  lazy?: boolean;
  className?: string;
  loading?: boolean;
  ariaLabel?: string;
  onLoadError?: () => void;
  onLoadSuccess?: () => void;
};

export const ImageCard = ({
  imageUuid,
  url,
  name,
  size,
  kind = 'basic',
  lazy,
  onClick,
  className,
  ariaLabel,
  onLoadError,
  onLoadSuccess,
}: ImageCardProps) => {
  const [themeColorer] = useKindTheme(kind);
  const canClick = !!onClick;

  const fallbackInitials = name
    .split(' ')
    .slice(0, 2)
    .map(v => upperCase(v.length > 0 ? v[0] : ''))
    .filter(v => !!v)
    .join(' ');

  useEffect(() => {
    if (!url) onLoadSuccess?.();
  }, []);

  return (
    <Card
      aria-label={ariaLabel}
      cardSize={size}
      onClick={onClick}
      className={clsx(canClick && 'pointer', className)}
      themeColorer={themeColorer}
    >
      {url && (
        <>
          <img
            id={imageUuid}
            className={clsx({ lazy })}
            width="100%"
            height="100%"
            src={lazy ? undefined : url}
            data-src={lazy ? url : undefined}
            onError={onLoadError}
            onLoad={onLoadSuccess}
          />
        </>
      )}
      {!url && (
        <>
          <FallbackText boxSize={size ?? '10rem'}>
            <strong>{fallbackInitials}</strong>
          </FallbackText>
        </>
      )}
    </Card>
  );
};

type CardProps = CanColor & { cardSize?: CardSize };

const Card = styled.div<CardProps>`
  overflow: hidden;
  border-radius: 0.25rem;
  border: 1px solid ${({ themeColorer }) => themeColorer()};
  width: ${({ cardSize = '7.75rem' }) => cardSize};
  height: ${({ cardSize = '7.75rem' }) => cardSize};
`;
