import { theme as aemikoTheme } from '@aemiko/ui';
import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import clsx from 'clsx';

type WithClassNameProps = { className?: string };

export const LoadingScreen = React.memo(({ className }: WithClassNameProps) => (
  <LoadingContainer className={className}>
    <BeatLoader loading color={aemikoTheme['info-500']} speedMultiplier={1} size={25} />
  </LoadingContainer>
));

export const LoadingWidet = React.memo(({ className }: WithClassNameProps) => (
  <div className={clsx('flex justify-center', className)}>
    <BeatLoader loading color={aemikoTheme['info-500']} speedMultiplier={1} size={25} />
  </div>
));

const LoadingContainer = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
