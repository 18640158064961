/* eslint-disable */
/*
THIS IS AN AUTO-GENERATED FILE
  .                          
                     M                          
                    dM                          
                    MMr                         
                   4MMML                  .     
                   MMMMM.                xf     
   .              "MMMMM               .MM-     
    Mh..          +MMMMMM            .MMMM      
    .MMM.         .MMMMML.          MMMMMh      
     )MMMh.        MMMMMM         MMMMMMM       
      3MMMMx.     'MMMMMMf      xnMMMMMM"       
      '*MMMMM      MMMMMM.     nMMMMMMP"        
        *MMMMMx    "MMMMM    .MMMMMMM=         
         *MMMMMh   "MMMMM"   JMMMMMMP           
           MMMMMM   3MMMM.  dMMMMMM            .
            MMMMMM  "MMMM  .MMMMM(        .nnMP"
=..          *MMMMx  MMM"  dMMMM"    .nnMMMMM*  
  "MMn...     'MMMMr 'MM   MMM"   .nMMMMMMM*"   
   "4MMMMnn..   *MMM  MM  MMP"  .dMMMMMMM""     
     ^MMMMMMMMx.  *ML "M .M*  .MMMMMM**"        
        *PMMMMMMhn. *x > M  .MMMM**""           
           ""**MMMMhx/.h/ .=*"                  
                    .3P"%....                   
                  nP"     "*MMnx 

run 'yarn codegen' to create a new one
*/
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
};

export interface Address {
  city: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  state: GeoStateCodes;
  zip: Scalars['String'];
}

export interface AdminUserInput {
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  organizationId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  role: UserRole;
}

export const AppView = {
  Abp: 'abp',
  Verde: 'verde',
} as const;

export type AppView = typeof AppView[keyof typeof AppView];
export interface ApplyPromoCodeResult {
  promoCode?: Maybe<PromoCode>;
  /** Promo code was already applied */
  promoCodeAlreadyApplied?: Maybe<Scalars['Boolean']>;
  /** Does promo code belong to an org outside of this cart */
  promoCodeBelongsToDifferentOrg?: Maybe<Scalars['Boolean']>;
  /** Promo code is expired or used up */
  promoCodeExpiredOrUsed?: Maybe<Scalars['Boolean']>;
  /** Promo code was not found */
  promoCodeNotFound?: Maybe<Scalars['Boolean']>;
}

export const Cannabinoid = {
  Cbd: 'CBD',
  Cbg: 'CBG',
  Thc: 'THC',
} as const;

export type Cannabinoid = typeof Cannabinoid[keyof typeof Cannabinoid];
export interface Cart {
  cartItemCount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  items: Array<CartItem>;
  organization: Organization;
  organizationId: Scalars['Float'];
  promoCodes: Array<PromoCode>;
  user?: Maybe<User>;
  uuid: Scalars['String'];
}

export interface CartItem {
  itemCount: Scalars['Float'];
  productQuantity: ProductQuantity;
}

export interface CartItemInput {
  itemCount: Scalars['Float'];
  productQuantityId: Scalars['Float'];
}

export interface Config {
  acceptedMimeTypes: Array<Scalars['String']>;
  featureFlags: Array<FeatureFlag>;
  publicFlags: Array<FeatureFlag>;
}

export type ConfigFeatureFlagsArgs = {
  appView: AppView;
};

export type Copy = ListCopy | TextCopy;

export const CopyBreak = {
  OrderedList: 'OrderedList',
  Paragraph: 'Paragraph',
  UnorderedList: 'UnorderedList',
} as const;

export type CopyBreak = typeof CopyBreak[keyof typeof CopyBreak];
export const DiscountType = {
  Number: 'Number',
  Percent: 'Percent',
} as const;

export type DiscountType = typeof DiscountType[keyof typeof DiscountType];
export interface Emission {
  data: Scalars['JSON'];
  key: Scalars['String'];
  user: User;
  view: AppView;
}

export const FeatureFlag = {
  FrontendEvents: 'FrontendEvents',
  MarketingReq: 'MarketingReq',
  PageVisits: 'PageVisits',
} as const;

export type FeatureFlag = typeof FeatureFlag[keyof typeof FeatureFlag];
export interface FlowerLibrary {
  additionalInfo?: Maybe<Copy>;
  cbcPercent?: Maybe<Scalars['Float']>;
  cbdPercent?: Maybe<Scalars['Float']>;
  cbgPercent?: Maybe<Scalars['Float']>;
  cbnPercent?: Maybe<Scalars['Float']>;
  effects: Array<Scalars['String']>;
  helpsWith: Array<Scalars['String']>;
  key: Scalars['String'];
  similar: Array<Scalars['String']>;
  smellAndTaste?: Maybe<Array<Scalars['String']>>;
  snakeCaseName: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  terpenes?: Maybe<Array<Terpene>>;
  thcPercent?: Maybe<Scalars['Float']>;
  thcvPercent?: Maybe<Scalars['Float']>;
  type: FlowerType;
  uploads: Array<Upload>;
}

export const FlowerType = {
  Hybrid: 'Hybrid',
  Indica: 'Indica',
  IndicaDominant: 'IndicaDominant',
  Sativa: 'Sativa',
  SativaDominant: 'SativaDominant',
} as const;

export type FlowerType = typeof FlowerType[keyof typeof FlowerType];
export const GeoStateCodes = {
  Ak: 'AK',
  Al: 'AL',
  Ar: 'AR',
  As: 'AS',
  Az: 'AZ',
  Ca: 'CA',
  Co: 'CO',
  Ct: 'CT',
  Dc: 'DC',
  De: 'DE',
  Fl: 'FL',
  Fm: 'FM',
  Ga: 'GA',
  Gu: 'GU',
  Hi: 'HI',
  Ia: 'IA',
  Id: 'ID',
  Il: 'IL',
  In: 'IN',
  Ks: 'KS',
  Ky: 'KY',
  La: 'LA',
  Ma: 'MA',
  Md: 'MD',
  Me: 'ME',
  Mh: 'MH',
  Mi: 'MI',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Ms: 'MS',
  Mt: 'MT',
  Nc: 'NC',
  Nd: 'ND',
  Ne: 'NE',
  Nh: 'NH',
  Nj: 'NJ',
  Nm: 'NM',
  Nv: 'NV',
  Ny: 'NY',
  Oh: 'OH',
  Ok: 'OK',
  Or: 'OR',
  Pa: 'PA',
  Pr: 'PR',
  Pw: 'PW',
  Ri: 'RI',
  Sc: 'SC',
  Sd: 'SD',
  Tn: 'TN',
  Tx: 'TX',
  Ut: 'UT',
  Va: 'VA',
  Vi: 'VI',
  Vt: 'VT',
  Wa: 'WA',
  Wi: 'WI',
  Wv: 'WV',
  Wy: 'WY',
} as const;

export type GeoStateCodes = typeof GeoStateCodes[keyof typeof GeoStateCodes];
export interface HealthCheck {
  status: Scalars['String'];
}

export interface ListCopy {
  break?: Maybe<CopyBreak>;
  bullets: Array<Scalars['String']>;
}

export interface MarketingRequestInput {
  email: Scalars['String'];
  ipv4?: Maybe<Scalars['String']>;
  ipv6?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  name: Scalars['String'];
  organization?: Maybe<Scalars['String']>;
}

export interface Mutation {
  abandonCart: Scalars['Boolean'];
  addCartItem: Scalars['Boolean'];
  attachPromoCode: ApplyPromoCodeResult;
  beginEmailVerification: Scalars['Boolean'];
  beginLibraryUpload: SignedS3Upload;
  beginPasswordReset: Scalars['Boolean'];
  beginPasswordlessFlow: Scalars['Boolean'];
  beginPhoneVerification: Scalars['Boolean'];
  beginProductUpload: SignedS3Upload;
  beginUpload: SignedS3Upload;
  completeOrder: Scalars['Boolean'];
  createEmployee: User;
  createProducts: Array<Scalars['String']>;
  deactivateEmployee: Scalars['Boolean'];
  deleteCartItem: Scalars['Boolean'];
  deleteRolodex: Scalars['Boolean'];
  deleteUpload: Scalars['Boolean'];
  finishPasswordReset: SuccessfulAuth;
  finishPasswordlessFlow: PasswordlessLoginResult;
  keepSessionAlive: Scalars['String'];
  loginWithPassword: SuccessfulAuth;
  removePromoCode: Scalars['Boolean'];
  signUp: Scalars['Boolean'];
  startNewCart: Cart;
  submitMarketingRequest: Scalars['Boolean'];
  /** track events from the frontend */
  track: Scalars['Boolean'];
  trackPageVisit: Scalars['Boolean'];
  updateCartItemCount: Scalars['Boolean'];
  updateLibraryUploadsOrder: Scalars['Boolean'];
  updateProductUploadsOrder: Scalars['Boolean'];
  upsertRolodex: Scalars['Boolean'];
}

export type MutationAbandonCartArgs = {
  cartUuid: Scalars['String'];
};

export type MutationAddCartItemArgs = {
  cartUuid: Scalars['String'];
  newItem: CartItemInput;
};

export type MutationAttachPromoCodeArgs = {
  cartUuid: Scalars['String'];
  code: Scalars['String'];
};

export type MutationBeginLibraryUploadArgs = {
  key: Scalars['String'];
  mimeType: Scalars['String'];
};

export type MutationBeginPasswordResetArgs = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type MutationBeginPasswordlessFlowArgs = {
  email: Scalars['String'];
};

export type MutationBeginProductUploadArgs = {
  mimeType: Scalars['String'];
  productId: Scalars['String'];
};

export type MutationBeginUploadArgs = {
  mimeType: Scalars['String'];
};

export type MutationCompleteOrderArgs = {
  cartUuid: Scalars['String'];
};

export type MutationCreateEmployeeArgs = {
  createUserInput: AdminUserInput;
};

export type MutationCreateProductsArgs = {
  organizationId: Scalars['Float'];
  products: Array<ProductInput>;
};

export type MutationDeactivateEmployeeArgs = {
  employeeId: Scalars['Int'];
};

export type MutationDeleteCartItemArgs = {
  cartUuid: Scalars['String'];
  productQuantityId: Scalars['Float'];
};

export type MutationDeleteRolodexArgs = {
  organizationId: Scalars['Float'];
};

export type MutationDeleteUploadArgs = {
  uploadId: Scalars['String'];
};

export type MutationFinishPasswordResetArgs = {
  email?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationFinishPasswordlessFlowArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationLoginWithPasswordArgs = {
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type MutationRemovePromoCodeArgs = {
  cartUuid: Scalars['String'];
  code: Scalars['String'];
};

export type MutationSignUpArgs = {
  consentedToTerms: Scalars['Boolean'];
  email: Scalars['String'];
};

export type MutationStartNewCartArgs = {
  existingCartUuid?: Maybe<Scalars['String']>;
  items: Array<CartItemInput>;
  organizationId: Scalars['Float'];
};

export type MutationSubmitMarketingRequestArgs = {
  marketingReq: MarketingRequestInput;
};

export type MutationTrackArgs = {
  appView: AppView;
  data?: Maybe<Scalars['JSON']>;
  key: Scalars['String'];
};

export type MutationTrackPageVisitArgs = {
  pageVisitInfo: PageVisitInput;
};

export type MutationUpdateCartItemCountArgs = {
  cartUuid: Scalars['String'];
  newCount: Scalars['Float'];
  productQuantityId: Scalars['Float'];
};

export type MutationUpdateLibraryUploadsOrderArgs = {
  key: Scalars['String'];
  orderInputs: Array<OrderUploadInput>;
};

export type MutationUpdateProductUploadsOrderArgs = {
  orderInputs: Array<OrderUploadInput>;
  productId: Scalars['String'];
};

export type MutationUpsertRolodexArgs = {
  input: RolodexInput;
  organizationId: Scalars['Float'];
};

export interface OrderReceipt {
  confirmationEmail?: Maybe<Scalars['String']>;
  orgKey: Scalars['String'];
  organizationAddress?: Maybe<Address>;
}

export interface OrderUploadInput {
  order: Scalars['Float'];
  uploadId: Scalars['String'];
}

export interface Organization {
  address?: Maybe<Address>;
  childOrganizations: Array<Organization>;
  email?: Maybe<Scalars['String']>;
  employeeCount: Scalars['Int'];
  employeeLimit?: Maybe<Scalars['Int']>;
  employeeThresholdMet: Scalars['Boolean'];
  employeeThresoldMet: Scalars['Boolean'];
  employees: Array<User>;
  id: Scalars['Int'];
  logo?: Maybe<Upload>;
  name: Scalars['String'];
  orgKey: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  type: OrganizationType;
}

export interface OrganizationFeature {
  feature: OrganizationFeatureType;
  value: Scalars['JSON'];
}

export const OrganizationFeatureType = {
  Dashboards: 'Dashboards',
  Rolodex: 'Rolodex',
} as const;

export type OrganizationFeatureType =
  typeof OrganizationFeatureType[keyof typeof OrganizationFeatureType];
export const OrganizationType = {
  Delivery: 'delivery',
  Delphi: 'delphi',
  Dispensary: 'dispensary',
} as const;

export type OrganizationType = typeof OrganizationType[keyof typeof OrganizationType];
export interface PageVisitInput {
  ipv4?: Maybe<Scalars['String']>;
  ipv4Geo?: Maybe<Scalars['JSON']>;
  ipv6?: Maybe<Scalars['String']>;
  ipv6Geo?: Maybe<Scalars['JSON']>;
}

export interface PasswordlessLoginResult {
  activationLinkExpired?: Maybe<Scalars['Boolean']>;
  auth?: Maybe<SuccessfulAuth>;
}

export const Permission = {
  AemikoAdmin: 'AemikoAdmin',
  EmployeeCreate: 'EmployeeCreate',
  EmployeeDelete: 'EmployeeDelete',
  EmployeeEdit: 'EmployeeEdit',
  EmployeeView: 'EmployeeView',
  FeaturesEdit: 'FeaturesEdit',
  FeaturesView: 'FeaturesView',
  LibraryUpload: 'LibraryUpload',
  OrganizationCreate: 'OrganizationCreate',
  OrganizationDashboards: 'OrganizationDashboards',
  OrganizationDelete: 'OrganizationDelete',
  OrganizationEdit: 'OrganizationEdit',
  OrganizationTransactions: 'OrganizationTransactions',
  OrganizationView: 'OrganizationView',
  ProductCreate: 'ProductCreate',
  ProductEdit: 'ProductEdit',
  RolodexEdit: 'RolodexEdit',
  RolodexView: 'RolodexView',
} as const;

export type Permission = typeof Permission[keyof typeof Permission];
export interface Product {
  brand?: Maybe<Scalars['String']>;
  cannabinoids: Array<Cannabinoid>;
  category: ProductCategory;
  /** Only visible to abp users */
  cbdMg?: Maybe<Scalars['Float']>;
  cbdPercent?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  discountedAt?: Maybe<Scalars['DateTime']>;
  discounts: Array<ProductDiscount>;
  displayableTags: Array<Scalars['String']>;
  flowerType?: Maybe<FlowerType>;
  /** Only visible to abp users */
  harvestDate?: Maybe<Scalars['DateTime']>;
  inStock: Scalars['Boolean'];
  inventory?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  organizationId: Scalars['Float'];
  /** Only visible to abp users */
  packDate?: Maybe<Scalars['DateTime']>;
  /** There's some brittleness in the system here. It's expected that every product has at least one quantity */
  primaryQuantity: ProductQuantity;
  /** Don't expose the organization model to through the vanilla product model */
  productOrgKey: Scalars['String'];
  quantities: Array<ProductQuantity>;
  rating?: Maybe<Scalars['Float']>;
  relatedProducts: Array<Product>;
  reviews: Array<ProductReview>;
  subCategory?: Maybe<Scalars['String']>;
  terpenes: Array<Terpene>;
  /** Only visible to abp users */
  thcMg?: Maybe<Scalars['Float']>;
  thcPercent?: Maybe<Scalars['Float']>;
  uploads: Array<Upload>;
  uuid: Scalars['String'];
}

export const ProductCategory = {
  Bong: 'Bong',
  Concentrate: 'Concentrate',
  Edible: 'Edible',
  Flower: 'Flower',
  Hash: 'Hash',
  Hookah: 'Hookah',
  Oil: 'Oil',
  Other: 'Other',
  Paper: 'Paper',
  Pipe: 'Pipe',
  PreRoll: 'PreRoll',
  Vaporizer: 'Vaporizer',
} as const;

export type ProductCategory = typeof ProductCategory[keyof typeof ProductCategory];
export interface ProductDiscount {
  createdAt: Scalars['DateTime'];
  discountType: DiscountType;
  expiresAt?: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
}

export interface ProductFilters {
  cannabinoids?: Maybe<Array<Cannabinoid>>;
  categories?: Maybe<Array<ProductCategory>>;
  cultivar?: Maybe<Array<FlowerType>>;
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  terpenes?: Maybe<Array<Terpene>>;
}

export interface ProductInput {
  brand?: Maybe<Scalars['String']>;
  cannabinoids?: Maybe<Array<Cannabinoid>>;
  category: ProductCategory;
  cbdMg?: Maybe<Scalars['Float']>;
  cbdPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  displayableTags?: Maybe<Array<Scalars['String']>>;
  flowerType?: Maybe<FlowerType>;
  harvestDate?: Maybe<Scalars['DateTime']>;
  internalTags?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  packDate?: Maybe<Scalars['DateTime']>;
  /** Must have at least 1 quantity */
  quantities: Array<ProductQuantityInput>;
  subCategory?: Maybe<Scalars['String']>;
  terpenes?: Maybe<Array<Terpene>>;
  thcMg?: Maybe<Scalars['Float']>;
  thcPercent?: Maybe<Scalars['Float']>;
}

export interface ProductQuantity {
  cost: Scalars['Float'];
  discountedCost?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  inStock: Scalars['Boolean'];
  /** Only visible to respective organization */
  inventory?: Maybe<Scalars['Float']>;
  product: Product;
  /** Size of this quantity */
  quantity: Scalars['Float'];
  quantityMetric?: Maybe<Scalars['String']>;
  /** How many servings in this quantity */
  serving: Scalars['Float'];
}

export interface ProductQuantityInput {
  cost: Scalars['Float'];
  inventory?: Maybe<Scalars['Float']>;
  primary?: Maybe<Scalars['Boolean']>;
  /** Size of this quantity */
  quantity?: Maybe<Scalars['Float']>;
  quantityMetric?: Maybe<Scalars['String']>;
  /** How many servings in this quantity */
  serving?: Maybe<Scalars['Float']>;
}

export interface ProductReview {
  createdAt: Scalars['DateTime'];
  details?: Maybe<Scalars['String']>;
  product: Product;
  rating: Scalars['Float'];
  reviewerName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface ProductTransaction {
  createdAt: Scalars['DateTime'];
  items: Array<ProductTransactionItem>;
  user?: Maybe<User>;
  uuid: Scalars['String'];
}

export interface ProductTransactionItem {
  cost: Scalars['Float'];
  itemCount: Scalars['Int'];
  product: Product;
  productQuantity: Scalars['String'];
}

export interface PromoCode {
  blackListedCategories: Array<ProductCategory>;
  code: Scalars['String'];
  exceedsUsage: Scalars['Boolean'];
  maxDiscounted?: Maybe<Scalars['Float']>;
  minSpent?: Maybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  type: DiscountType;
}

export interface Query {
  adminOrganizationProducts: Array<Product>;
  config: Config;
  currentOrganization: Organization;
  currentUser: User;
  events: Emission;
  findCartByUuid: Cart;
  flowerLibrary: Array<FlowerLibrary>;
  healthcheck: HealthCheck;
  orderReceipt: OrderReceipt;
  organization: Organization;
  organizationByKey: Organization;
  organizationFeatures: Array<OrganizationFeature>;
  organizationProduct: Product;
  organizationProducts: Array<Product>;
  organizationPromoCode?: Maybe<PromoCode>;
  productsByOrganizationId: Array<Product>;
  /** Limit how much data is exposed publicily */
  publicOrganizations: Array<Organization>;
  rolodex?: Maybe<Rolodex>;
  supremeOrganizations: Array<Organization>;
  supremeUsers: Array<User>;
  transactions: Array<ProductTransaction>;
}

export type QueryAdminOrganizationProductsArgs = {
  organizationId: Scalars['Float'];
};

export type QueryFindCartByUuidArgs = {
  cartUuid: Scalars['String'];
};

export type QueryOrderReceiptArgs = {
  cartUuid: Scalars['String'];
};

export type QueryOrganizationArgs = {
  id: Scalars['Int'];
};

export type QueryOrganizationByKeyArgs = {
  key: Scalars['String'];
};

export type QueryOrganizationFeaturesArgs = {
  organizationId: Scalars['Float'];
};

export type QueryOrganizationProductArgs = {
  productId: Scalars['String'];
};

export type QueryOrganizationProductsArgs = {
  filters?: Maybe<ProductFilters>;
  orgKey: Scalars['String'];
};

export type QueryOrganizationPromoCodeArgs = {
  code: Scalars['String'];
  organizationId: Scalars['Float'];
};

export type QueryProductsByOrganizationIdArgs = {
  limit?: Maybe<Scalars['Float']>;
  organizationId: Scalars['Int'];
  query: Scalars['String'];
};

export type QueryPublicOrganizationsArgs = {
  orgType?: Maybe<OrganizationType>;
};

export type QueryRolodexArgs = {
  organizationId: Scalars['Float'];
};

export interface Rolodex {
  data: Scalars['String'];
  delimiter: Scalars['String'];
  hasHeader: Scalars['Boolean'];
}

export interface RolodexInput {
  data: Scalars['String'];
  delimiter: Scalars['String'];
  hasHeader: Scalars['Boolean'];
}

export interface SignedS3Upload {
  fields: Array<Array<Scalars['String']>>;
  url: Scalars['String'];
  uuid: Scalars['String'];
}

export interface SuccessfulAuth {
  accessToken: Scalars['String'];
  appView: AppView;
  userId: Scalars['Int'];
}

export const Terpene = {
  Humulene: 'Humulene',
  Limonene: 'Limonene',
  Linalool: 'Linalool',
  Mycrene: 'Mycrene',
  Pinene: 'Pinene',
} as const;

export type Terpene = typeof Terpene[keyof typeof Terpene];
export interface TextCopy {
  text: Scalars['String'];
}

export interface Upload {
  completedAt?: Maybe<Scalars['DateTime']>;
  creatorName: Scalars['String'];
  creatorUserId: Scalars['Float'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  /** Signed aws url to GET */
  url: Scalars['String'];
  uuid: Scalars['String'];
}

export interface User {
  appView: AppView;
  cart?: Maybe<Cart>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizations: Array<Organization>;
  permissions: Array<Permission>;
  phone?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Upload>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  userStatus: UserStatus;
}

export const UserRole = {
  Admin: 'admin',
  Limited: 'limited',
  Owner: 'owner',
  Supreme: 'supreme',
  User: 'user',
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];
export const UserStatus = {
  Active: 'active',
  Deleted: 'deleted',
  Ineligible: 'ineligible',
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];
export type OrdersPageQueryVariables = Exact<{ [key: string]: never }>;

export type OrdersPageQuery = {
  transactions: Array<{
    createdAt: Date;
    uuid: string;
    user?:
      | { email?: string | null | undefined; phone?: string | null | undefined }
      | null
      | undefined;
    items: Array<{
      productQuantity: string;
      cost: number;
      itemCount: number;
      product: { name: string; category: ProductCategory };
    }>;
  }>;
};

export type SuccessfulAuthFragment = { accessToken: string; appView: AppView; userId: number };

export type LoginMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password: Scalars['String'];
}>;

export type LoginMutation = {
  loginWithPassword: { accessToken: string; appView: AppView; userId: number };
};

export type KeepSessionAliveMutationVariables = Exact<{ [key: string]: never }>;

export type KeepSessionAliveMutation = { keepSessionAlive: string };

export type BeginPasswordlessFlowMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type BeginPasswordlessFlowMutation = { beginPasswordlessFlow: boolean };

export type FinishPasswordlessFlowMutationVariables = Exact<{
  token: Scalars['String'];
  email: Scalars['String'];
}>;

export type FinishPasswordlessFlowMutation = {
  finishPasswordlessFlow: {
    activationLinkExpired?: boolean | null | undefined;
    auth?: { accessToken: string; appView: AppView; userId: number } | null | undefined;
  };
};

export type FinishPasswordResetMutationVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;

export type FinishPasswordResetMutation = {
  finishPasswordReset: { accessToken: string; appView: AppView; userId: number };
};

export type BeginPasswordResetMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;

export type BeginPasswordResetMutation = { beginPasswordReset: boolean };

export type RootUserFragment = {
  id: number;
  firstName?: string | null | undefined;
  preferredName?: string | null | undefined;
  lastName?: string | null | undefined;
  appView: AppView;
  permissions: Array<Permission>;
};

export type EmployeeeFragment = {
  id: number;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  userStatus: UserStatus;
};

export type TrackMutationVariables = Exact<{
  key: Scalars['String'];
  data?: Maybe<Scalars['JSON']>;
}>;

export type TrackMutation = { track: boolean };

export type CreateEmployeeMutationVariables = Exact<{
  createUserInput: AdminUserInput;
}>;

export type CreateEmployeeMutation = { createEmployee: { id: number } };

export type EventsQueryVariables = Exact<{ [key: string]: never }>;

export type EventsQuery = { events: { key: string; data: { [key: string]: any } } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  currentUser: {
    id: number;
    firstName?: string | null | undefined;
    preferredName?: string | null | undefined;
    lastName?: string | null | undefined;
    appView: AppView;
    permissions: Array<Permission>;
  };
  config: { featureFlags: Array<FeatureFlag> };
};

export type EmployeesQueryVariables = Exact<{ [key: string]: never }>;

export type EmployeesQuery = {
  currentOrganization: {
    employees: Array<{
      id: number;
      firstName?: string | null | undefined;
      lastName?: string | null | undefined;
      userStatus: UserStatus;
    }>;
  };
};

export const SuccessfulAuthFragmentDoc = gql`
  fragment successfulAuth on SuccessfulAuth {
    accessToken
    appView
    userId
  }
`;
export const RootUserFragmentDoc = gql`
  fragment rootUser on User {
    id
    firstName
    preferredName
    lastName
    appView
    permissions
  }
`;
export const EmployeeeFragmentDoc = gql`
  fragment employeee on User {
    id
    firstName
    lastName
    userStatus
  }
`;
export const OrdersPageDocument = gql`
  query ordersPage {
    transactions {
      createdAt
      uuid
      user {
        email
        phone
      }
      items {
        product {
          name
          category
        }
        productQuantity
        cost
        itemCount
      }
    }
  }
`;

/**
 * __useOrdersPageQuery__
 *
 * To run a query within a React component, call `useOrdersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersPageQuery, OrdersPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrdersPageQuery, OrdersPageQueryVariables>(
    OrdersPageDocument,
    options
  );
}
export function useOrdersPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersPageQuery, OrdersPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrdersPageQuery, OrdersPageQueryVariables>(
    OrdersPageDocument,
    options
  );
}
export type OrdersPageQueryHookResult = ReturnType<typeof useOrdersPageQuery>;
export type OrdersPageLazyQueryHookResult = ReturnType<typeof useOrdersPageLazyQuery>;
export type OrdersPageQueryResult = ApolloReactCommon.QueryResult<
  OrdersPageQuery,
  OrdersPageQueryVariables
>;
export const LoginDocument = gql`
  mutation login($email: String, $phone: String, $password: String!) {
    loginWithPassword(email: $email, phone: $phone, password: $password) {
      ...successfulAuth
    }
  }
  ${SuccessfulAuthFragmentDoc}
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const KeepSessionAliveDocument = gql`
  mutation keepSessionAlive {
    keepSessionAlive
  }
`;
export type KeepSessionAliveMutationFn = ApolloReactCommon.MutationFunction<
  KeepSessionAliveMutation,
  KeepSessionAliveMutationVariables
>;

/**
 * __useKeepSessionAliveMutation__
 *
 * To run a mutation, you first call `useKeepSessionAliveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKeepSessionAliveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [keepSessionAliveMutation, { data, loading, error }] = useKeepSessionAliveMutation({
 *   variables: {
 *   },
 * });
 */
export function useKeepSessionAliveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    KeepSessionAliveMutation,
    KeepSessionAliveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<KeepSessionAliveMutation, KeepSessionAliveMutationVariables>(
    KeepSessionAliveDocument,
    options
  );
}
export type KeepSessionAliveMutationHookResult = ReturnType<typeof useKeepSessionAliveMutation>;
export type KeepSessionAliveMutationResult =
  ApolloReactCommon.MutationResult<KeepSessionAliveMutation>;
export type KeepSessionAliveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  KeepSessionAliveMutation,
  KeepSessionAliveMutationVariables
>;
export const BeginPasswordlessFlowDocument = gql`
  mutation beginPasswordlessFlow($email: String!) {
    beginPasswordlessFlow(email: $email)
  }
`;
export type BeginPasswordlessFlowMutationFn = ApolloReactCommon.MutationFunction<
  BeginPasswordlessFlowMutation,
  BeginPasswordlessFlowMutationVariables
>;

/**
 * __useBeginPasswordlessFlowMutation__
 *
 * To run a mutation, you first call `useBeginPasswordlessFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginPasswordlessFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginPasswordlessFlowMutation, { data, loading, error }] = useBeginPasswordlessFlowMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useBeginPasswordlessFlowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BeginPasswordlessFlowMutation,
    BeginPasswordlessFlowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BeginPasswordlessFlowMutation,
    BeginPasswordlessFlowMutationVariables
  >(BeginPasswordlessFlowDocument, options);
}
export type BeginPasswordlessFlowMutationHookResult = ReturnType<
  typeof useBeginPasswordlessFlowMutation
>;
export type BeginPasswordlessFlowMutationResult =
  ApolloReactCommon.MutationResult<BeginPasswordlessFlowMutation>;
export type BeginPasswordlessFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BeginPasswordlessFlowMutation,
  BeginPasswordlessFlowMutationVariables
>;
export const FinishPasswordlessFlowDocument = gql`
  mutation finishPasswordlessFlow($token: String!, $email: String!) {
    finishPasswordlessFlow(token: $token, email: $email) {
      activationLinkExpired
      auth {
        ...successfulAuth
      }
    }
  }
  ${SuccessfulAuthFragmentDoc}
`;
export type FinishPasswordlessFlowMutationFn = ApolloReactCommon.MutationFunction<
  FinishPasswordlessFlowMutation,
  FinishPasswordlessFlowMutationVariables
>;

/**
 * __useFinishPasswordlessFlowMutation__
 *
 * To run a mutation, you first call `useFinishPasswordlessFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishPasswordlessFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishPasswordlessFlowMutation, { data, loading, error }] = useFinishPasswordlessFlowMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFinishPasswordlessFlowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FinishPasswordlessFlowMutation,
    FinishPasswordlessFlowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FinishPasswordlessFlowMutation,
    FinishPasswordlessFlowMutationVariables
  >(FinishPasswordlessFlowDocument, options);
}
export type FinishPasswordlessFlowMutationHookResult = ReturnType<
  typeof useFinishPasswordlessFlowMutation
>;
export type FinishPasswordlessFlowMutationResult =
  ApolloReactCommon.MutationResult<FinishPasswordlessFlowMutation>;
export type FinishPasswordlessFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FinishPasswordlessFlowMutation,
  FinishPasswordlessFlowMutationVariables
>;
export const FinishPasswordResetDocument = gql`
  mutation finishPasswordReset(
    $newPassword: String!
    $token: String!
    $email: String
    $phone: String
  ) {
    finishPasswordReset(email: $email, phone: $phone, newPassword: $newPassword, token: $token) {
      ...successfulAuth
    }
  }
  ${SuccessfulAuthFragmentDoc}
`;
export type FinishPasswordResetMutationFn = ApolloReactCommon.MutationFunction<
  FinishPasswordResetMutation,
  FinishPasswordResetMutationVariables
>;

/**
 * __useFinishPasswordResetMutation__
 *
 * To run a mutation, you first call `useFinishPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishPasswordResetMutation, { data, loading, error }] = useFinishPasswordResetMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useFinishPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FinishPasswordResetMutation,
    FinishPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FinishPasswordResetMutation,
    FinishPasswordResetMutationVariables
  >(FinishPasswordResetDocument, options);
}
export type FinishPasswordResetMutationHookResult = ReturnType<
  typeof useFinishPasswordResetMutation
>;
export type FinishPasswordResetMutationResult =
  ApolloReactCommon.MutationResult<FinishPasswordResetMutation>;
export type FinishPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FinishPasswordResetMutation,
  FinishPasswordResetMutationVariables
>;
export const BeginPasswordResetDocument = gql`
  mutation beginPasswordReset($email: String, $phone: String) {
    beginPasswordReset(email: $email, phone: $phone)
  }
`;
export type BeginPasswordResetMutationFn = ApolloReactCommon.MutationFunction<
  BeginPasswordResetMutation,
  BeginPasswordResetMutationVariables
>;

/**
 * __useBeginPasswordResetMutation__
 *
 * To run a mutation, you first call `useBeginPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginPasswordResetMutation, { data, loading, error }] = useBeginPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useBeginPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BeginPasswordResetMutation,
    BeginPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BeginPasswordResetMutation,
    BeginPasswordResetMutationVariables
  >(BeginPasswordResetDocument, options);
}
export type BeginPasswordResetMutationHookResult = ReturnType<typeof useBeginPasswordResetMutation>;
export type BeginPasswordResetMutationResult =
  ApolloReactCommon.MutationResult<BeginPasswordResetMutation>;
export type BeginPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BeginPasswordResetMutation,
  BeginPasswordResetMutationVariables
>;
export const TrackDocument = gql`
  mutation track($key: String!, $data: JSON) {
    track(key: $key, appView: abp, data: $data)
  }
`;
export type TrackMutationFn = ApolloReactCommon.MutationFunction<
  TrackMutation,
  TrackMutationVariables
>;

/**
 * __useTrackMutation__
 *
 * To run a mutation, you first call `useTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackMutation, { data, loading, error }] = useTrackMutation({
 *   variables: {
 *      key: // value for 'key'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTrackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TrackMutation, TrackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TrackMutation, TrackMutationVariables>(
    TrackDocument,
    options
  );
}
export type TrackMutationHookResult = ReturnType<typeof useTrackMutation>;
export type TrackMutationResult = ApolloReactCommon.MutationResult<TrackMutation>;
export type TrackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TrackMutation,
  TrackMutationVariables
>;
export const CreateEmployeeDocument = gql`
  mutation createEmployee($createUserInput: AdminUserInput!) {
    createEmployee(createUserInput: $createUserInput) {
      id
    }
  }
`;
export type CreateEmployeeMutationFn = ApolloReactCommon.MutationFunction<
  CreateEmployeeMutation,
  CreateEmployeeMutationVariables
>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      createUserInput: // value for 'createUserInput'
 *   },
 * });
 */
export function useCreateEmployeeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEmployeeMutation,
    CreateEmployeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(
    CreateEmployeeDocument,
    options
  );
}
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = ApolloReactCommon.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEmployeeMutation,
  CreateEmployeeMutationVariables
>;
export const EventsDocument = gql`
  query events {
    events {
      key
      data
    }
  }
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}
export function useEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...rootUser
    }
    config {
      featureFlags(appView: abp)
    }
  }
  ${RootUserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const EmployeesDocument = gql`
  query employees {
    currentOrganization {
      employees {
        ...employeee
      }
    }
  }
  ${EmployeeeFragmentDoc}
`;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument,
    options
  );
}
export function useEmployeesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument,
    options
  );
}
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>;
export type EmployeesQueryResult = ApolloReactCommon.QueryResult<
  EmployeesQuery,
  EmployeesQueryVariables
>;
