import { css } from 'styled-components';

export const mobileXsMaxWidth = 300; // ps
export const mobileMaxWidth = 480; // px
export const tabletOrMobileMaxWidth = 800; // px

export const mobilePadding = css`
  padding: 2em 1.5em;
`;

export const desktopPadding = css`
  padding: 4em 3em;
`;
