import { AemikoKind, Button, Label, theme } from '@aemiko/ui';
import { capitalize } from 'lodash';
import { useHistory } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { Text } from '../../Base';
import { useEmployeesQuery, UserStatus } from '../../graphQL';

const userStatusToKind: Record<UserStatus, AemikoKind> = {
  [UserStatus.Active]: 'success',
  [UserStatus.Deleted]: 'danger',
  [UserStatus.Ineligible]: 'warning',
};

export const Employees = () => {
  const { data, loading } = useEmployeesQuery();
  const history = useHistory();

  if (!data || loading) {
    return (
      <div className="flex items-center justify-center">
        <BeatLoader size={24} loading color={theme['primary-700']} />
      </div>
    );
  }

  return (
    <div className="center pa5">
      <Text.h2 className="mb2">Employees</Text.h2>
      <div className="flex flex-column gap-3 mb4">
        {data.currentOrganization.employees.map(e => (
          <div key={e.lastName} className="flex flex-row gap-4 items-center">
            <Text.body>
              {e.firstName} {e.lastName}
            </Text.body>
            <Label>{capitalize(e.userStatus)}</Label>
          </div>
        ))}
      </div>
      <Button kind="primary" onClick={() => history.push('/store/team/create')}>
        Create New
      </Button>
    </div>
  );
};
