import styled from 'styled-components';
import { PaddedLayout } from '../../Components/Layout/PaddedLayout';

export const AuthContainer = styled(PaddedLayout).attrs({
  paddingTop: { mobile: '5.5em', desktop: '10em' },
})`
  width: 100%;
  max-width: 32em;
  margin: 0 auto;
`;
