import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';

const SvgCheckmarkCircle2Outline = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#79D33D"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
    <path d="m14.7 8.39-3.78 5-1.63-2.11a1 1 0 0 0-1.58 1.23l2.43 3.11a1 1 0 0 0 .79.38 1 1 0 0 0 .79-.39l4.57-6a1 1 0 1 0-1.6-1.22z" />
  </svg>
);

const ForwardRef = forwardRef(SvgCheckmarkCircle2Outline);
export default ForwardRef;
