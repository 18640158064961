import { Button, InputRHF, useLoading } from '@aemiko/ui';
import { isValidEmail, isValidPhone, wait } from '@aemiko/utils';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Text } from '../../Base';
import { PaddedLayout } from '../../Components/Layout/PaddedLayout';
import { useBeginPasswordResetMutation } from '../../graphQL';
import { AuthContainer } from './Common';

const ONE_SECOND = 1000;

type PassResetForm = {
  emailOrPhone: string;
};

export const PasswordReset = () => {
  const [complete, setComplete] = useState<string>();
  const { setLoading } = useLoading();
  const [sendPasswordResetToken] = useBeginPasswordResetMutation();
  const form = useForm<PassResetForm>();

  const submit = form.handleSubmit(async ({ emailOrPhone }) => {
    try {
      setLoading(true);
      // gives a good effect
      await wait(1.5 * ONE_SECOND);
      sendPasswordResetToken({
        variables: {
          email: isValidEmail(emailOrPhone) ? emailOrPhone : undefined,
          phone: isValidEmail(emailOrPhone) ? undefined : emailOrPhone,
        },
        ignoreResults: true,
        errorPolicy: 'ignore',
      });
      setComplete(emailOrPhone);
    } finally {
      setLoading(false);
    }
  });

  if (complete) {
    return (
      <AuthContainer>
        <Text.bodyBig>A password reset token has been sent to: {complete}</Text.bodyBig>
      </AuthContainer>
    );
  }

  return (
    <AuthContainer>
      <Text.h1 className="mb2">Password Reset</Text.h1>
      <Text.body className="mb4">
        Enter the phone number or email address associated to your account
      </Text.body>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <InputRHF
            name="emailOrPhone"
            autoComplete="email"
            rules={{
              validate: v =>
                isValidPhone(v) || isValidEmail(v) || 'Please enter a valid email or phone',
            }}
            controlProps={{ label: 'Email or phone', className: 'mb3', required: true }}
          />
        </form>
      </FormProvider>
      <div className="mv2">
        <Button onClick={submit} type="submit">
          Submit
        </Button>
      </div>
    </AuthContainer>
  );
};
