import styled from 'styled-components';

export const PhantomButton = styled.button`
  display: flex;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  z-index: 1;
`;
