import { RemSize, EmSize } from '@aemiko/ui';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { desktopPadding, mobilePadding, tabletOrMobileMaxWidth } from './constants';

type Size = EmSize | RemSize | number;
type SizeOptions = { mobile: Size; desktop: Size };

type Overrides = {
  paddingTop?: SizeOptions;
  paddingRight?: SizeOptions;
  paddingBottom?: SizeOptions;
  paddingLeft?: SizeOptions;
};

export const PaddedLayout = styled.div<Overrides>`
  ${mobilePadding}
  ${({ paddingTop, paddingRight, paddingBottom, paddingLeft }) =>
    getStyle('mobile', { paddingTop, paddingRight, paddingBottom, paddingLeft })}
  @media only screen and (min-width: ${tabletOrMobileMaxWidth}px) {
    ${desktopPadding}
    ${({ paddingTop, paddingRight, paddingBottom, paddingLeft }) =>
      getStyle('desktop', { paddingTop, paddingRight, paddingBottom, paddingLeft })};
  }
`;

const getStyle = (kind: keyof SizeOptions, overrides: Overrides): FlattenSimpleInterpolation => {
  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = overrides;
  const styles: FlattenSimpleInterpolation[] = [];

  if (paddingTop) {
    const tSize = paddingTop[kind];
    styles.push(css`
      padding-top: ${typeof tSize === 'number' ? `${tSize}px` : tSize};
    `);
  }
  if (paddingRight) {
    const rSize = paddingRight[kind];
    styles.push(css`
      padding-right: ${typeof rSize === 'number' ? `${rSize}px` : rSize};
    `);
  }
  if (paddingBottom) {
    const bSize = paddingBottom[kind];
    styles.push(css`
      padding-bottom: ${typeof bSize === 'number' ? `${bSize}px` : bSize};
    `);
  }
  if (paddingLeft) {
    const lSize = paddingLeft[kind];
    styles.push(css`
      padding-left: ${typeof lSize === 'number' ? `${lSize}px` : lSize};
    `);
  }

  return styles.reduce((acc, curr) => {
    return acc.concat(curr);
  }, css``);
};
