import { LoadContextProvider, ThemeProvider } from '@aemiko/ui';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/link-error';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AuthContextProvider } from './Components/Context/AuthContext';
import { NavLayout } from './Components/Layout/NavLayout';
import config from './config';
import useKeepSessionAlive from './Hooks/useKeepSessionAlive';
import { ChangePassword } from './Pages/Auth/ChangePassword';
import { LoginOptions } from './Pages/Auth/LoginOptions';
import { PasswordReset } from './Pages/Auth/PasswordReset';
import { Home } from './Pages/Home';
import { Orders } from './Pages/Orders';
import { CreateEmployee } from './Pages/Team/CreateEmployee';
import { Employees } from './Pages/Team/Employees';
import { getAuthToken } from './token';
import { isLoggedIn, isTokenExpired, logout } from './utils';
import 'ka-table/style.scss';

const SIX_MINUTES_MS = 6 * 60 * 1000;

const httpLink = new HttpLink({ uri: `${config.apiUrl}/graphql` });

const errorHandler = onError(({ graphQLErrors }) => {
  graphQLErrors?.forEach(async gqlError => {
    const statusCode = gqlError?.extensions?.exception?.httpStatus;
    if (statusCode === 401) {
      logout();
      window.location.reload();
    }
    // eslint-disable-next-line no-console
    console.log('GQL', gqlError);
  });
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAuthToken();
  if (isTokenExpired(token)) logout();
  const headers = { authorization: token ? `Bearer ${token}` : '' };
  operation.setContext({ headers });
  return forward(operation);
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  // @ts-ignore
  link: authMiddleware.concat(errorHandler.concat(httpLink)),
  defaultOptions: {
    query: { fetchPolicy: 'no-cache' },
    mutate: { fetchPolicy: 'no-cache' },
    watchQuery: { fetchPolicy: 'no-cache' },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider>
          <LoadContextProvider>
            <PublicRoutes>
              <Authenticated />
            </PublicRoutes>
          </LoadContextProvider>
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}

const PublicRoutes: React.FC = ({ children }) => (
  <Switch>
    <Route path="/login" component={LoginOptions} />
    <Route path="/change-password" component={ChangePassword} />
    <Route path="/password-reset" component={PasswordReset} />
    {children}
  </Switch>
);

export const Authenticated = () => {
  useKeepSessionAlive(SIX_MINUTES_MS);

  if (!isLoggedIn()) {
    return <Redirect to={{ pathname: '/login', state: { from: window.location.pathname } }} />;
  }

  return (
    <AuthContextProvider>
      <Switch>
        <NavLayout>
          <Route exact path="/home" component={Home} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/store/team" component={Employees} />
          <Route exact path="/store/team/create" component={CreateEmployee} />
          <Redirect from="*" to="/orders" />
        </NavLayout>
      </Switch>
    </AuthContextProvider>
  );
};

export default App;
