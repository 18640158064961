import { Alert, Button, InputRHF, useLoading } from '@aemiko/ui';
import { isValidEmail, isValidPhone, wait } from '@aemiko/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Text } from '../../Base';
import config from '../../config';
import { AppView, useLoginMutation } from '../../graphQL';
import { setAuthToken, setVerdeAuth } from '../../token';

const ONE_SECOND = 1000;

type LoginForm = {
  emailOrPhone: string;
  password: string;
};

const required = 'required';

export const PasswordLogin = () => {
  const { setLoading } = useLoading();
  const [loginUser, { error }] = useLoginMutation();
  const history = useHistory();
  const form = useForm<LoginForm>();

  const submit = form.handleSubmit(async ({ emailOrPhone, password }) => {
    try {
      setLoading(true);
      // gives a good effect
      await wait(1.5 * ONE_SECOND);
      const { data } = await loginUser({
        variables: {
          email: isValidEmail(emailOrPhone) ? emailOrPhone : undefined,
          phone: isValidEmail(emailOrPhone) ? undefined : emailOrPhone,
          password,
        },
      });
      if (data?.loginWithPassword) {
        const { accessToken, appView } = data.loginWithPassword;
        // redirect if verde
        if (appView === AppView.Verde) {
          setVerdeAuth(accessToken);
          history.push(config.verdeUrl);
        } else {
          setAuthToken(accessToken);
          history.push('/home');
        }
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <>
      {error && (
        <Alert className="mb4 dib">
          <Text.bodySmall kind="danger" className="di">
            The email or password you entered is incorrect. Please try again or{' '}
          </Text.bodySmall>
          <Text.linkSmall to="/reset-password">reset your password</Text.linkSmall>.
        </Alert>
      )}
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <InputRHF
            name="emailOrPhone"
            autoComplete="email"
            rules={{
              validate: v =>
                isValidPhone(v) || isValidEmail(v) || 'Please enter a valid email or phone',
            }}
            controlProps={{ label: 'Email or phone', className: 'mb3', required: true }}
          />
          <InputRHF
            name="password"
            rules={{ required }}
            type="password"
            autoComplete="current-password"
            controlProps={{ label: 'Password', className: 'mb3' }}
          />
          <div className="flex flex-row-reverse mb4">
            <Text.linkCaption kind="black" to="/password-reset">
              Forgot password?
            </Text.linkCaption>
          </div>
          <div className="mv2">
            <Button onClick={submit} type="submit">
              <Text.bodySmall>Sign in</Text.bodySmall>
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
