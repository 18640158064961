import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';
import { linkStyle, TextProps } from './Text/Text';
import { useTheme } from './Theme';

export const BackButton = ({
  className,
  ...props
}: Pick<
  React.HTMLProps<HTMLButtonElement>,
  'className' | 'style' | 'id' | 'onClick' | 'disabled' | 'aria-label'
>) => {
  const { theme } = useTheme();
  return (
    <LButton kind="info" className={clsx('flex flex-row items-center', className)} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="1.25rem"
        height="1.25rem"
        fill={theme['info-500']}
      >
        <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0" />
        <path d="M13.36 17a1 1 0 0 1-.72-.31l-3.86-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.42L10.9 12l3.18 3.3a1 1 0 0 1 0 1.41 1 1 0 0 1-.72.29z" />
      </svg>
      back
    </LButton>
  );
};

const LButton = styled.button<TextProps>`
  ${linkStyle}
`;
