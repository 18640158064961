import { Text as TextBase } from '@aemiko/ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const link = styled(Link)<TextBase.TextProps>`
  ${TextBase.linkStyle}
`;

const linkSmall = styled(Link)<TextBase.TextProps>`
  ${TextBase.linkStyle}
  font-size: 0.875em;
`;

const linkCaption = styled(Link)<TextBase.TextProps>`
  ${TextBase.linkStyle}
  font-size: 0.75em;
  line-height: 0.9375em;
`;

const linkButton = styled<'button'>(link as any).attrs({
  as: 'button',
})<TextBase.LinkButtonProps>`
  ${TextBase.linkButtonStyle}
`;

const linkButtonBig = styled<'button'>(link as any).attrs({
  as: 'button',
})<TextBase.LinkButtonProps>`
  ${TextBase.linkButtonStyle}
  font-size: 1.5em;
`;

const linkButtonSmall = styled<'button'>(link as any).attrs({
  as: 'button',
})<TextBase.LinkButtonProps>`
  ${TextBase.linkButtonStyle}
  font-size: 0.875em;
`;

const linkButtonCaption = styled<'button'>(link as any).attrs({
  as: 'button',
})<TextBase.LinkButtonProps>`
  ${TextBase.linkButtonStyle}
  font-size: 0.75em;
  line-height: 0.9375em;
`;

export const Text = {
  ...TextBase,
  link,
  linkSmall,
  linkCaption,
  linkButton,
  linkButtonBig,
  linkButtonSmall,
  linkButtonCaption,
};

export default Text;
