import { BulbOutline } from '@aemiko/icons';
import { Alert, Button, InputRHF } from '@aemiko/ui';
import { isValidEmail, Nullable } from '@aemiko/utils';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Text } from '../../Base';
import config from '../../config';
import { useBeginPasswordlessFlowMutation } from '../../graphQL';

export const PassswordlessLogin = () => {
  const loginForm = useForm<{ email: string }>();
  const [sentEmail, setSentEmail] = useState<Nullable<string>>(null);
  const [beginPasswordlessFlow, { error, loading }] = useBeginPasswordlessFlowMutation({
    ignoreResults: true,
  });

  const submit = loginForm.handleSubmit(async ({ email }) => {
    await beginPasswordlessFlow({ variables: { email } });
    setSentEmail(email);
  });

  if (sentEmail) {
    return <Confirmation sentEmail={sentEmail} />;
  }

  return (
    <>
      {error && (
        <Alert className="mb4 dib">
          <Text.bodySmall kind="danger" className="di">
            Something went wrong, please try again. If this seems wrong,{' '}
            <Text.externalLink href={`mailto:${config.contactEmail}`}>contact us</Text.externalLink>
            .
          </Text.bodySmall>
        </Alert>
      )}
      <FormProvider {...loginForm}>
        <form onSubmit={submit}>
          <InputRHF
            name="email"
            autoComplete="email"
            rules={{ validate: v => isValidEmail(v) || 'Please enter a valid email' }}
            controlProps={{ label: 'Email', className: 'mb4', required: true }}
          />
          <Button onClick={submit} type="submit" loading={loading}>
            <Text.bodySmall>Sign in with email</Text.bodySmall>
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

type ConfirmationProps = {
  sentEmail: string;
};

const Confirmation = ({ sentEmail }: ConfirmationProps) => (
  <>
    <div className="mb3 w-100">
      <BulbOutline width={40} height={40} className="db center" />
    </div>
    <Text.h1>Smart link sent</Text.h1>
    <Text.body>
      A special link has been sent to {sentEmail}. Use this link to authenticate.
    </Text.body>
  </>
);
