import React from 'react';
import styled from 'styled-components';
import { AemikoKind, CanColor, useKindTheme } from '../Theme';

type ProgressBarProps = {
  kind: AemikoKind;
  percentCompleted: number;
};

export const ProgressBar = ({ kind, percentCompleted }: ProgressBarProps) => {
  const [colorer] = useKindTheme(kind);
  const normalized = Math.min(Math.max(percentCompleted, 0), 100);
  return (
    <ProgressContainer role="progressbar">
      <Progress percentCompleted={normalized} themeColorer={colorer} />
    </ProgressContainer>
  );
};

const ProgressContainer = styled.div`
  height: 0.3rem;
  width: 100%;
  background-color: #cccccc;
  border-radius: 50vmin;
  margin: 1rem 0;
`;

const Progress = styled.div<CanColor & { percentCompleted: number }>`
  height: 100%;
  width: ${({ percentCompleted }) => `${percentCompleted}%`};
  background-color: ${({ themeColorer }) => themeColorer()};
  border-radius: inherit;
  transition: width 0.25s linear;
`;
