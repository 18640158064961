import clsx from 'clsx';
import keyboardKey from 'keyboard-key';
import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../Theme';
import { ThemedElement } from '../Theme/types';
import { keyDownFactory } from './utils';

export type CheckboxProps = {
  className?: string;
  error?: boolean;
  required?: boolean;
  checked?: boolean;
  onChange: (val: boolean) => void;
  children?: React.ReactNode;
  name?: string;
};

export const Checkbox = ({
  className,
  children,
  error,
  required,
  checked,
  onChange,
  ...props
}: CheckboxProps) => {
  const { theme } = useTheme();
  const handleChange = () => onChange(!checked);
  const onKeyDown = keyDownFactory({ [keyboardKey.Enter]: handleChange });
  return (
    <div className={clsx('flex items-start justify-start mt2', className)}>
      <div className="relative">
        <StyledCheckbox
          {...props}
          tabIndex={0}
          onClick={handleChange}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          role="checkbox"
          className={clsx({ error, checked }, className)}
          aria-invalid={!!error}
          aria-required={!!required}
          aemikoTheme={theme}
        />
        <div className="checkbox-content" />
      </div>
      <div>{children}</div>
    </div>
  );
};

const StyledCheckbox = styled.div<ThemedElement>`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;

  ~ .checkbox-content {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.6rem;
  }

  &:focus-visible ~ .checkbox-content {
    outline: 1px solid ${({ aemikoTheme }) => aemikoTheme['info-300']};
  }

  & ~ .checkbox-content {
    border-radius: 20%;
    padding: 10%;
    background-color: ${({ aemikoTheme }) => aemikoTheme['basic-200']};
  }

  &.checked ~ .checkbox-content {
    background-color: ${({ aemikoTheme }) => aemikoTheme['secondary-500']};
    background-image: url('data:image/svg+xml,%0A%20%20%20%20%3Csvg%20width%3D%2217%22%20height%3D%2213%22%20viewBox%3D%220%200%2017%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M6.50002%2012.6L0.400024%206.60002L2.60002%204.40002L6.50002%208.40002L13.9%200.900024L16.1%203.10002L6.50002%2012.6Z%22%20fill%3D%22%23FFFFFF%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E%0A%20%20');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-origin: content-box;
  }

  &.error:not(:focus) ~ .checkbox-content {
    background-color: ${({ aemikoTheme }) => aemikoTheme['danger-transparent-500']};
  }
`;
