import clsx from 'clsx';
import isString from 'lodash/isString';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '../Text';

type LabelProps = {
  className?: string;
  children: React.ReactText;
};

export const Label = ({ children, className }: LabelProps) => (
  <LabelContainer className={className}>
    <Text.label kind="black">{children}</Text.label>
  </LabelContainer>
);

type ExpandableProps = {
  className?: string;
  label: string;
  renderContent: React.ReactNode;
};

export const ExpandableLabel = ({ label, className, renderContent }: ExpandableProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (expanded) ref.current?.scrollIntoView();
  }, [expanded]);
  return (
    <ExpandableLabelContainer
      ref={ref}
      className={className}
      onClick={() => setExpanded(ex => !ex)}
    >
      <div className="flex flex-row gap-1 items-center justify-center">
        <Text.label kind="black">{label}</Text.label>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
          <path d="M20 5a1 1 0 0 0-1-1h-5a1 1 0 0 0 0 2h2.57l-3.28 3.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L18 7.42V10a1 1 0 0 0 1 1 1 1 0 0 0 1-1z" />
          <path d="M10.71 13.29a1 1 0 0 0-1.42 0L6 16.57V14a1 1 0 0 0-1-1 1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2H7.42l3.29-3.29a1 1 0 0 0 0-1.42z" />
        </svg>
      </div>
      <div className={clsx('label-content', { expanded })}>
        {isString(renderContent) ? <Text.bodySmall>{renderContent}</Text.bodySmall> : renderContent}
      </div>
    </ExpandableLabelContainer>
  );
};

const labelStyle = css`
  background-color: white;
  padding: 0.5rem 0.75rem;
  max-width: fit-content;
  min-width: 6rem;
  border-radius: 0.125rem;
  text-align: center;
  border: 1px solid black;
`;

const ExpandableLabelContainer = styled.div`
  ${labelStyle}
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;

  & > div.label-content {
    display: none;
    opacity: 0;
  }

  & > div.label-content.expanded {
    text-align: initial;
    display: block;
    opacity: 1;
  }
`;

const LabelContainer = styled.div`
  ${labelStyle}
`;
