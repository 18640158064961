import React, { createContext, useContext, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import styled from 'styled-components';

export const LoadContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Context.Provider value={{ loading, setLoading }}>
      <div className="relative">
        <BarContainer>
          <BarLoader
            width="100%"
            height="0.25rem"
            loading={loading}
            color="rgba(71, 86, 255, 0.65)"
            speedMultiplier={0.75}
          />
        </BarContainer>
      </div>
      {children}
    </Context.Provider>
  );
};

export const useLoading = () => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('Attempting to useLoading outside of context...');
  }
  return ctx;
};

type LoadContext = {
  loading: boolean;
  setLoading: (loading: boolean | ((l: boolean) => boolean)) => void;
};

const Context = createContext<LoadContext | null>(null);

const BarContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
`;
