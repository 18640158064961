import { BulbOutline } from '@aemiko/icons';
import { ThemedElement, useTheme } from '@aemiko/ui';
import { useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../Base';
import { AuthContainer } from './Common';
import { PassswordlessLogin } from './PasswordlessLogin';
import { PasswordLogin } from './PasswordLogin';

export enum LoginType {
  Passwordless = 'passwordless',
  Password = 'password',
}

type Props = {
  initialType?: LoginType;
};

export const LoginOptions = ({ initialType }: Props) => {
  const [loginType, setLoginType] = useState(initialType ?? LoginType.Passwordless);
  return (
    <AuthContainer>
      <Text.h1 className="mb4">Welcome back</Text.h1>
      {loginType === LoginType.Passwordless ? (
        <>
          <PassswordlessLogin />
          <SmartLinkDialogBox>
            <Text.bodySmall>
              A smart link will be sent to your email for a password-free sign in. Have a password?{' '}
              <Text.linkButton onClick={() => setLoginType(LoginType.Password)}>
                Sign in using a password.
              </Text.linkButton>
            </Text.bodySmall>
          </SmartLinkDialogBox>
        </>
      ) : (
        <>
          <PasswordLogin />
          <SmartLinkDialogBox>
            <Text.bodySmall>
              Haven&apos;t set a password yet?{' '}
              <Text.linkButton onClick={() => setLoginType(LoginType.Passwordless)}>
                Use a smart link.
              </Text.linkButton>
            </Text.bodySmall>
          </SmartLinkDialogBox>
        </>
      )}
    </AuthContainer>
  );
};

const SmartLinkDialogBox: React.FC = ({ children }) => {
  const { theme } = useTheme();
  return (
    <DialogContainer aemikoTheme={theme} className="flex items-center flex-row gap-3">
      <BulbOutline width={26} height={26} className="flex-shrink-0" />
      <div>{children}</div>
    </DialogContainer>
  );
};

const DialogContainer = styled.div<ThemedElement>`
  padding: 1.25rem;
  border-radius: 0.25rem;
  background-color: ${({ aemikoTheme }) => aemikoTheme['basic-500']};
  margin-top: 2.5rem;
`;
