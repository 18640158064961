import { Nullable } from '@aemiko/utils';
import clsx from 'clsx';
import React, { createContext, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import uuid from 'uuid';
import { MinusSvg, PlusSvg } from '../Common';
import { Text } from '../Text';

type IAccordionCtx = {
  onClickPanel: (id: Nullable<string>) => void;
  selectedPanel: Nullable<string>;
};

const AccordionCtx = createContext<IAccordionCtx | null>(null);

const useAccordion = () => {
  const ctx = useContext(AccordionCtx);
  if (!ctx) {
    throw new Error('useAccordion called outside of context');
  }
  return ctx;
};

export const Accordion: React.FC = ({ children }) => {
  const [selectedPanel, setSelectedPanel] = useState<Nullable<string>>();
  return (
    <AccordionCtx.Provider value={{ selectedPanel, onClickPanel: v => setSelectedPanel(v) }}>
      <div className="flex flex-column gap-3">{children}</div>
    </AccordionCtx.Provider>
  );
};

type AccordionPanelProps = {
  children: React.ReactNode;
  title: string | JSX.Element;
  labelClassName?: string;
};

export const AccordionPanel = ({ children, labelClassName, title }: AccordionPanelProps) => {
  const panelId = useRef(uuid.v4());
  const { selectedPanel, onClickPanel } = useAccordion();
  const isPanelSelected = selectedPanel === panelId.current;
  const Icon = isPanelSelected ? MinusSvg : PlusSvg;
  return (
    <PanelContainer>
      <PanelLabel
        className={labelClassName}
        onClick={() => onClickPanel(isPanelSelected ? null : panelId.current)}
      >
        <Icon size={15} />
        {typeof title === 'string' ? (
          <Text.bodySmallBold>{title}</Text.bodySmallBold>
        ) : (
          <>{title}</>
        )}
      </PanelLabel>
      <HiddenPanelContent className={clsx(isPanelSelected && 'selected')}>
        {children}
      </HiddenPanelContent>
    </PanelContainer>
  );
};

const PanelContainer = styled.div`
  overflow: hidden;
`;

const PanelLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const HiddenPanelContent = styled.div`
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  transition-property: max-height, height, padding;
  padding: 0 1.5em;

  &.selected {
    transition-timing-function: ease-out;
    height: auto;
    max-height: 100%;
    padding: 1em 1.5rem;
  }
`;
