import {
  BrowserOutline,
  FolderOutline,
  Globe2Outline,
  GridOutline,
  MessageCircleOutline,
  PeopleOutline,
  QuestionMarkCircleOutline,
  Settings2Outline,
} from '@aemiko/icons';
import { useTheme } from '@aemiko/ui';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getSimplePathname } from '../../utils';

type NavItem = {
  title: string;
  goTo: string;
  isActive: boolean;
  isSection?: boolean;
  isHidden?: boolean;
  iconComponent: (p: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

export const useNav = () => {
  const { pathname } = useLocation();
  const activeItem = getSimplePathname(pathname);
  const { theme } = useTheme();
  const navItems: NavItem[] = useMemo(
    () =>
      [
        {
          title: 'Dashboard',
          goTo: '/home',
          iconComponent: GridOutline,
        },
        {
          title: 'Orders',
          goTo: '/orders',
          iconComponent: Globe2Outline,
        },
        {
          title: 'My Store',
          goTo: '/store',
          iconComponent: BrowserOutline,
        },
        {
          title: 'Team',
          goTo: '/store/team',
          iconComponent: PeopleOutline,
        },
        {
          title: 'Messages',
          goTo: '/messages',
          iconComponent: MessageCircleOutline,
        },
        {
          title: 'Inventory',
          goTo: '/inventory',
          iconComponent: FolderOutline,
        },
        {
          isSection: true,
          title: 'Help',
          goTo: '/faq',
          iconComponent: QuestionMarkCircleOutline,
        },
        {
          title: 'Settings',
          goTo: '/settings',
          iconComponent: Settings2Outline,
        },
      ].map(v => ({ ...v, isActive: getSimplePathname(v.goTo) === activeItem })) as NavItem[],
    [activeItem]
  );

  return { navItems, activeItem };
};
